import React from "react"
import { Seo } from "src/component/common/seo"
import Industries from "src/component/layout/industries"
import { StaticImage } from "gatsby-plugin-image"
import FoodCasestudyList from "src/component/list/foodCasestudyList"

export default function Food() {
    return (
        <>
        <Industries>
          <div className="food inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_food.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                              placeholder="blurred"
                              className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_food_sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                              placeholder="blurred"
                              className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>食品</p>
                                  <span>FOOD</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                 在庫管理や需要予測の課題を解決し、廃棄ロス削減や効率的な生産計画を実現します。
                                  <br />
                                  食品大手企業への豊富なAI導入実績を基に、食品業界特化の高精度なAIモデルを提供します。
                              
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIで実現する、<br />持続可能な食品<br className="c-sp" />サプライチェーン</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>データ基盤の構築</h3>
                                 <p>
                                    社内外のデータを統合し、リアルタイムで在庫・販売状況を可視化。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>販促戦略の最適化</h3>
                                 <p>
                                   AIが市場トレンドを分析し、最適なプロモーション施策を提案。
                                 </p>
                                 </li>
                                 <li className="introduction__content__text">
                                 <h3>AI需要予測の導入</h3>
                                 <p>
                                   過去の販売データを分析し、最適な生産・発注計画を立案。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>サプライチェーン全体の最適化</h3>
                                 <p>
                                   拠点拡大や物流最適化により、食品ロス削減とコスト削減を実現。
                                 </p>
                                  </li>
                                 <li className="introduction__content__text">
                                 <h3>自動発注システムの構築</h3>
                                 <p>
                                   AIの需要予測をもとに、発注業務を自動化し作業負担を軽減。
                                  </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <FoodCasestudyList />
                    </div>
                </section>
                </div>
            </Industries>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="FOOD"
            description=""
            pathname=""
        />
    )
}