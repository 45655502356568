import React, { useState } from "react";
import Button from "src/component/common/button";
import { Link } from "gatsby";
import { StaticImage,GatsbyImage,getImage } from "gatsby-plugin-image";
import { ImgixGatsbyImage } from "@imgix/gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Loader from "src/component/common/loader";

export default function FoodCasestudyList() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMicrocmsCasestudy {
        edges {
          node {
            casestudyId
            company_name
            display_date(formatString: "YYYY-MM-DD")
            id
            title
            ai_services_category {
              id
              name
            }
            type_category {
              id
              name
            }
            uses_category {
              id
              name
            }
            casestudy_thumbnail {
              url
              width
              height
            }
            contents
          }
        }
      }
    }
  `);
    
    
  //foodの記事を表示
  const allArticles = data.allMicrocmsCasestudy.edges.filter(({ node }) =>
  node.type_category?.some(category => category.name === "食品" || category.id === "food" || category.id === "foods")
  );
    console.log(allArticles);
  

  const [visibleCount, setVisibleCount] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const INCREMENT = 6;

  const loadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleCount((prev) => prev + INCREMENT);
      setIsLoading(false);
    }, 1000);
  };

  const convertId = (id) => id.replace(/_/g, "-");

  return (
    <>
          <ol className="resouses-articles-list">
            
        {allArticles.slice(0, visibleCount).map(({ node }) => (
            <li key={node.id}>
                <Link to={`/casestudy/${convertId(node.casestudyId)}`}>
                {node.casestudy_thumbnail?.url ? (
                    <div className="resouses-articles-list__image">
                    <ImgixGatsbyImage
                        src={node.casestudy_thumbnail.url}
                        imgixParams={{ auto: "format" }}
                        layout="fullWidth"
                        loading="lazy"
                        style={{ height: "100%" }}
                        alt={node.title}
                        sourceWidth={node.casestudy_thumbnail.width}
                        sourceHeight={node.casestudy_thumbnail.height}
                    />


                    </div>
                ) : (
                    <div className="resouses-articles-list__image is-default">
                    <img src="../../assets/img/pages/top/casestudy_img01.png" alt="test" /> 
                        <GatsbyImage image="../../assets/img/pages/top/casestudy_img01.png" alt={node.title} />
                    </div>
                )}
                <div className="resouses-articles-list__text">
                    <span className="resouses-articles-list__name">{node.company_name}</span>
                    <h3 className="resouses-articles-list__title">{node.title}</h3>
                    {(node.type_category || node.uses_category || node.ai_services_category) && (
                    <ul className="resouses-articles-list__tag">
                        {node.type_category?.map((category) => (
                        <li key={category.id}>{category.name}</li>
                        ))}
                        {node.uses_category?.map((category) => (
                        <li key={category.id}>{category.name}</li>
                        ))}
                        {node.ai_services_category?.map((category) => (
                        <li key={category.id}>{category.name}</li>
                        ))}
                    </ul>
                    )}
                    <div className="resouses-articles-list__button">
                    <Button className="c-button01" label="詳しく見る" />
                    </div>
                </div>
                </Link>
            </li>
            ))}
              


          </ol>


          

      {isLoading && (
        <div className="resouses__loader">
          <Loader />
        </div>
      )}

      {visibleCount < allArticles.length && !isLoading && (
        <div className="resouses__button">
          <Button className="c-button03 is-white" label="さらに導入事例を表示" onClick={loadMore} />
        </div>
      )}
    </>
  );
}

